var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reports,"search":_vm.search,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order_inv || item.mem_package_no || item.claim_no || item.package_credits_on || item.note || '-')+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 'Add')?_c('span',{staticClass:"credit-push"},[_vm._v("เติม")]):_vm._e(),(item.type === 'Delete')?_c('span',{staticClass:"credit-remove"},[_vm._v("ถอน")]):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount_credit > 0)?_c('span',{staticClass:"credit-push"},[_vm._v(" +"+_vm._s(item.amount_credit)+" ")]):_vm._e(),(item.amount_credit <= 0)?_c('span',{staticClass:"credit-remove"},[_vm._v(" "+_vm._s(item.amount_credit)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }